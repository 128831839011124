import { graphql } from 'gatsby';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import CTASectionTrial from '../../components/CTASectionTrial/CTASectionTrial';
import DefaultPageHeader from '../../components/DefaultPageHeader/DefaultPageHeader';
import FeatureBlock from '../../components/FeatureBlock/FeatureBlock';
import PageSection from '../../components/PageSection/PageSection';
import PageTemplate from '../../components/PageTemplate/PageTemplate';
import { PageComponent } from '../../types/gatsby.types';
import { ColorPalette } from '../../utils/colors.utils';
import './single-feature.scss';

export const query = graphql`
query timeTrackingFeatureQuery {
  singlePost: mdx(fileAbsolutePath: {regex: "/features\/time-tracking/"}) {
    body
    frontmatter {
      title
      description
      overline
      featured_video {
        publicURL
      }
      featured_image {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      color
    }
    id
    slug
  },
  featuredVideo: file(relativePath: {eq: "videos/features/time-tracking/single-feature-featured-video.mp4"}) {
    publicURL
  }
  liveActivityImage: file(relativePath: {eq: "images/features/time-tracking/live-activity.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  toDosVideo: file(relativePath: {eq: "videos/features/time-tracking/single-feature-to-dos.mp4"}) {
    publicURL
  }
  completeTasksVideo: file(relativePath: {eq: "videos/features/time-tracking/single-feature-complete-tasks.mp4"}) {
    publicURL
  }
}
`

const SingleFeature: PageComponent = props => {

  return <Observer children={() => (
    <PageTemplate 
      {...props} // always include
      className="SingleFeature SingleFeatureTimeTracking"
      title={props.data.singlePost.frontmatter.title}
      description={props.data.singlePost.frontmatter.description}
      backgroundColor={ColorPalette.white}
      foregroundColor={ColorPalette.dark}
    >

      <DefaultPageHeader
        id="SingleFeatureTimeTrackingHeader"
        className="SingularPageHeader" // Singular is the WP naming for both default pages + posts + custom post types (as a fallback)
        overline="trackster feature"
        title="Acurate, Transparent Time Tracking"
        // title={props.data.singlePost.frontmatter.title}
        // subtitle="Visualise your entire team's performance with just a couple of clicks"
        children={
          <div>
            <p>
              Increase productivity through real-time and manual time tracking. Our automatic timesheet software allows your company to stay on track of projects. That way you can bill your team accurately and keep team members accountable.
            </p>
          </div>
        }
      ></DefaultPageHeader>

      {/* <PageSection id="SingleFeatureArticle SingleBlogArticle"> */}
      <PageSection id="SingleFeatureArticle">

        {
          props.data.featuredVideo ? 
          // We need the wrapping div to be able to style the video appropriately
          <div className="FeatureBlockVideo" id="SingleFeatureFeaturedVideo"> 
            <video autoPlay muted loop 
            // poster={image.publicURL}
            // TODO: Edge cases (no image / no video, etc)
            // TODO: Make this video element a reusable component
            >
              <source src={props.data.featuredVideo.publicURL} type="video/mp4"/>
            </video>
          </div>
          : null
          }


        <article className="SingleBlogArticleContent SingleFeatureArticleContent">
          {/* <p className='SingleFeatureDescription'>
            {props.data.singlePost.frontmatter.description}
          </p> */}

          <FeatureBlock
          id="SingleFeatureLiveActivity"
          title="See real time activity by all collaborators"
          // image={liveActivityImage}
          image={props.data.liveActivityImage}
          // overline="Filter everything"
          >
            <p>
              All team members see what everyone is working on, at all times, so you instantly know when it is a good time to get in touch.
            </p>
          </FeatureBlock>

          <FeatureBlock
          id="SingleFeatureCompleteTasks"
          title="Track time spent on each task, by any team member, until complete"
          // image={props.data.singlePost.frontmatter.featured_image}
          videoFilePath={props.data.completeTasksVideo.publicURL}
          // overline="Filter everything"
          >
            <p>
              Break down your project to smaller taks and simply track them, anywhere in the UI.
            </p>
            <p>
              Start and stop as many times as you need and when done, simply click the checkbox!
            </p>

          </FeatureBlock>

          <FeatureBlock
          id="SingleFeatureToDos"
          title="All your assigned tasks in one place"
          // image={props.data.singlePost.frontmatter.featured_image}
          videoFilePath={props.data.toDosVideo.publicURL}
          // overline="Filter everything"
          >
            <p>
              Complex projects with multiple collaborators are easily managed by assigning team members to each task, so you only see what is relevant to you!
            </p>
          </FeatureBlock>

          {/* <MDXRenderer>
            {props.data.singlePost.body}
          </MDXRenderer> */}

        </article>


      </PageSection>

      {/* <PageSection id="BlogEntryListSection">
        <h2 className="BlogEntryListSectionHeading">Latest articles</h2>
        <BlogEntryList
        id=""
        allMdx={props.data.allPosts}
        ></BlogEntryList>s
      </PageSection> */}

      <CTASectionTrial
      title="Want to test these and many more features live?"
      />

    </PageTemplate>
  )} />
}

export default SingleFeature;